.c-banner {
    margin-bottom: 30px;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.04), 0 0 2px rgba(0, 0, 0, 0.06), 0 4px 8px rgba(0, 0, 0, 0.04);

    & .o-btn_primary {
        width: 48px;
        height: 48px;
    }

    &__text {
        &_block {
            flex-direction: row;
            align-items: center;
            min-height: 106px;
            position: relative;
            top: unset;
            left: unset;
            flex-grow: 1;
            width: auto;
            max-width: none;
            height: auto;
            padding: 1rem 2rem;
            color: #000000;
            background: #FFFFFF;
            border: none;
            transform: unset;
            box-sizing: content-box;
            border-radius: 0 0 1rem 1rem;
            display: flex;
        }

        &_row {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: center;
            align-items: center;
            padding: 0.25rem 0 0;
            margin-bottom: 0.5rem;
        }

        &_mobile {
            width: min-content;
            & strong {
                font-size: 28px;
                font-weight: bold;
            }

            & span {
                color: #52575c;
                font-size: 16px;
            }
        }

        &_desktop {
            display: none;
        }
    }
}

