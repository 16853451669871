.c-jackpot {
    margin: 4rem 0;

    &_divider {
        &_top {
            position: absolute;
            left: 0;
            top: -0.9px;
            width: 100%;
            height: 0.5rem;
            margin: 0;
            background: url(../img/jackpot-arrow.svg);
            z-index: 1;
        }

        &_bottom {
            position: absolute;
            left: 0;
            bottom: -0.9px;
            width: 100%;
            height: 0.5rem;
            margin: 0;
            transform: rotate(180deg);
            background: url(../img/jackpot-arrow.svg);
        }
    }

    &_content {
        padding: 2rem 0.5rem 1rem;
        display: flex;
        flex-direction: column;
        position: relative;
        display: flex;
        align-items: center;
        background: center / cover url(../img/jackpot-banner.webp);
        opacity: 1;
    }

    &_title {
        margin: 0 0 4px 0;
        color: #000000;
        font-size: 24px;
        line-height: 1.75rem;
        font-weight: 700;
        margin-bottom: 20px;
        display: flex;
    }

    &_money_content {
        padding: 2rem 0.5rem;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        padding: 0.25rem 0.5rem 0;
    }

    &_block {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 16.25rem;
        min-width: 30rem;
        text-align: center;
        color: #FFFFFF;
        background: rgba(0, 8, 36, 0.5);
        border: 2px solid #2c5aff;
        border-radius: 1rem;

        &_title {
            font-size: 1.25rem;
            line-height: 1.5rem;
            font-weight: 700;
        }

        &_money {
            margin-top: 0.5rem;
            font-size: 32px;
            line-height: 1;
            font-weight: 700;
        }

        & .o-btn_blue {
            margin: 4px 0 -28px;
            font-size: 16px;
            line-height: 1.5rem;
            font-weight: 500;
            min-height: 56px;
            padding: 0.25rem 1.5rem;
            min-width: 140px;
        }
    }

    &_games {
        position: relative;
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: var(--ds2-rows, unset);
        grid-auto-flow: column;
        grid-auto-columns: unset;
        grid-column: 2 / 3;
        gap: 0;
        overflow-x: auto;
        overflow-y: hidden;
        width: auto;
        cursor: pointer;
        z-index: 0;
        scrollbar-width: none;

        &_item {
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            width: 100%;
            height: 60%;
            padding: 0.5rem;
            background: linear-gradient(0deg, #15161f 0%, rgba(20, 23, 34, 0.72) 46.67%, rgba(39, 43, 65, 0) 100%);
            border-radius: 8px;
            transition: 0.2s;
            opacity: 0;

            &:hover {
                opacity: 1;
            }


            &s {}
        }

        &_overlay {
            position: relative;
        }

        &_prize {
            font-size: 0.75rem;
            line-height: 1rem;
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 0.25rem 0.75rem;
            text-align: center;
            color: #FFFFFF;
            background: linear-gradient(150deg, #6C8DFF 0%, #1044FF 100%);
            border-radius: 0 0 8px 8px;
        }
    }

    &_bird {
        display: none;
    }

    & .c-slot {
        &__overflow {
            padding: 10px;
        }

        &__name {
            display: flex;
            align-items: center;
            margin: 8px 0 0 0;
            line-height: 1.875rem;
            font-size: 14px;
            color: #FFFFFF;
        }

        &__jackpot {
            font-size: 14px;
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 0.25rem 0.75rem;
            text-align: center;
            color: #FFFFFF;
            background: linear-gradient(150deg, #6C8DFF 0%, #1044FF 100%);
            border-radius: 0 0 8px 8px;
        }

        &s {
            position: relative;
            top: 46px;
        }
    }
}