.c-faq {
	& a:not([class])  {
		color: $link-hover;
		text-decoration: none;
		&:hover, &:focus {
				text-decoration: underline;
		}
	}
	margin-bottom: 30px;
	& details[open] summary {
		border-radius: 4px 4px 0 0;
	}
	&__item {
		display: -webkit-box;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
		margin-bottom: 10px;
	}
	&__title {
		cursor: pointer;
		padding: 7px;
		padding-left: 30px;
		background: linear-gradient(#1245FF 75%, #1245FF 100%) right bottom;
		color: #fff;
		font-weight: 600;
		font-size: 1.6rem;
		display: -webkit-inline-box;
		display: inline-flex;
		-webkit-box-align: center;
		align-items: center;
		border-radius: 4px;
		word-break: break-all;
		position: relative;
		&::-webkit-details-marker {
			display: none;
		}
		&:before {
			content: '►';
			position: absolute;
			display: -webkit-box;
			display: flex;
			left: 10px;
			-webkit-box-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			align-items: center;
			text-align: center;
			font-weight: 600;
			color: $white;
			width: 10px;
			height: 10px;
		}
	}
	&__content {
		padding: 10px 15px;
		border: 2px solid #1245FF;
		border-top: 0;
		border-radius: 0 0 4px 4px;
	}
}