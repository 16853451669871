.c-aside {
    background: linear-gradient(135deg, #B2C3FF 0%, #6385FF 28.38%, #1044FF 100%);
    border-radius: 0 1rem 0 0;
    position: fixed;
    left: 0;
    top: 0;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    transition: 0.2s;
    transform: translateX(-100%);
    z-index: -1;

    &__content {
        overflow-x: hidden;
        overflow-y: auto;
        height: calc(100% - 68px);
        padding: 1.5rem 2rem 2rem 2rem;
        color: #FFFFFF;
    }

    &__top {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 75px;
        padding: 0.75rem;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
        width: 100%;
    }

    &__line {
        width: calc(100% - 48px);
        margin: 0 auto;
        border-color: #FFFFFF;
        opacity: 0.2;
        border-top: 1px solid #D1D3D6;
    }

    &__caption {
        display: inline-flex;
        align-items: center;
        gap: 6px;
        font-size: 16px;

        &_green {
            line-height: 1.25rem;
            font-size: 18px;
            font-weight: 900;
            color: #4EE736;
        }
    }

    &__country-icon {
        transform: rotate(-90deg);
        margin-left: auto;
    }

    &__menu_buttons {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: center;

        & a:hover {
            background: linear-gradient(172deg, rgb(250 250 250 / 23%) 0%, rgb(255 255 255 / 24%) 100%);
        }

    }

    &__nav {
        margin: 20px 0 0 16px;
        display: flex;
        flex-direction: column;

        &_item {
            font-size: 18px;
            line-height: 1.5rem;
            font-weight: 600;
            color: inherit;
            display: flex;
            flex: 1;
            align-items: center;
            margin-bottom: 30px;
            gap: 20px;
        }
    }

    &__menu {
        &_bonus {
            position: relative;
            margin-left: -1.5rem;
            margin-bottom: 30px;

            & img {
                width: 252px;
            }

            & .c-aside__caption {
                flex-direction: column;
                gap: unset;
            }

            & .c-aside__line {
                width: 100%;
            }

            &_download {
                position: absolute;
                top: 7px;
                right: 22px;
                display: flex;
                flex-direction: column;
                align-items: center;
                overflow: hidden;
                width: 182px;
                height: 74px;
                padding: 0rem 2rem 0.5rem 0rem;
                background: rgba(255, 255, 255, 0.25);
                border: 1px solid rgba(255, 255, 255, 0.3);
                border-radius: 0.5rem;
                -webkit-backdrop-filter: blur(10px);
                backdrop-filter: blur(10px);

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 2rem;
                    height: 100%;
                    font-size: 1.5rem;
                    color: #1245FF;
                    background: #FFFFFF;
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
                }
            }

            &_caption {
                font-size: 14px;
                color: rgba(255, 255, 255, 0.7);
                font-weight: 500;
                text-align: center;
            }
        }

        &_support {
            position: relative;
            display: flex;
            align-items: center;
            width: 190px;
            height: 48px;
            padding: 0.5rem;
            color: #FFFFFF;
            background: linear-gradient(123deg, rgba(250, 250, 250, 0.1) 0%, rgba(235, 235, 235, 0.1) 100%);
            border-radius: 1.5rem;
            cursor: pointer;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(123deg, rgba(250, 250, 250, 0.05) 0%, rgba(235, 235, 235, 0.05) 100%);
                border-radius: 1.5rem;
                transition: 0.2s;
                opacity: 0;
            }
        }

        &_country {
            position: relative;
            display: flex;
            align-items: center;
            width: 190px;
            height: 48px;
            padding: 0.5rem;
            color: #FFFFFF;
            background: linear-gradient(123deg, rgba(250, 250, 250, 0.1) 0%, rgba(235, 235, 235, 0.1) 100%);
            border-radius: 1.5rem;
            cursor: pointer;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(123deg, rgba(250, 250, 250, 0.05) 0%, rgba(235, 235, 235, 0.05) 100%);
                border-radius: 1.5rem;
                transition: 0.2s;
                opacity: 0;
            }
        }

        &_icon {
            position: relative;
            overflow: hidden;
            width: 32px;
            height: 32px;
            margin-right: 0.5rem;
            border-radius: 50%;

            &-chat {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                box-shadow: inset 0 0 0 1px #FFFFFF;
            }

            &-flag {
                position: relative;
                overflow: hidden;
                margin-right: 0.5rem;
                border-radius: 50%;

                & svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    height: 100%;
                    transform: translate(-50%, -50%);
                }
            }
        }

    }
}

.is-aside-open {
    max-width: 260px;
    transform: translate(0);
    z-index: 1000;
}

.c-aside__close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #FFFFFF;
    padding: 12px;
}

.c-header__burger__menu:focus {
    display: none;
}