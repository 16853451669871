.c-footer {
    color: #FFFFFF;
    background-color: #1b1c29;

    &__container {
        padding: 0 16px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding-top: 30px;
    }

    &__info {
        &_icons {
            display: flex;
        }

        &_logo {
            display: flex;
            flex-direction: row;
            padding: 26px 0;
            order: 2;
            border-top: 1px solid #52575C;

            & span {
                font-size: 14px;
                line-height: 1.25rem;
                font-weight: 400;
                margin-bottom: 2.5rem;
                color: #A6ABB0;
                display: none;
            }
        }
    }

    &__games {
        flex-direction: column;
        align-items: flex-start;
        display: none;

        & span {
            font-size: 20px;
            line-height: 1.5rem;
            font-weight: 700;
            margin-bottom: 2em;
            color: #FFFFFF;
        }

        & div {
            flex-direction: column;
            display: flex;
        }


        & a {
            margin: 0 0 1rem;
            font-size: 14px;
            line-height: 1.25rem;
            font-weight: 400;
            color: #A6ABB0;

            &:hover {
                color: #FFFFFF;
                text-decoration: underline;
            }
        }

        &_mobile {
            padding: 12px;
            background: #282A39;
            border-radius: 8px;
            width: 100%;
            font-weight: 700;
            display: flex;
            margin-bottom: 10px;

            &_content {
                display: flex;
                flex-wrap: wrap;
                margin-top: 20px;

                & a {
                    margin: 0 0 1rem;
                    font-size: 14px;
                    line-height: 1.25rem;
                    font-weight: 400;
                    color: #A6ABB0;
                    flex-basis: 50%;
                    padding: 0 2rem 0 0;
                    margin-bottom: 2rem;
                    line-height: 1.5rem;
                }
            }
        }
    }

    &__info {
        display: none;
        flex-direction: column;
        align-items: flex-start;

        & span {
            font-size: 20px;
            line-height: 1.5rem;
            font-weight: 700;
            margin-bottom: 2rem;
            color: #FFFFFF;
        }

        & div {
            flex-direction: column;
            display: flex;
        }


        & a {
            margin: 0 0 1rem;
            font-size: 14px;
            line-height: 1.25rem;
            font-weight: 400;
            color: #A6ABB0;

            &:hover {
                color: #FFFFFF;
                text-decoration: underline;
            }
        }

        &_mobile {
            padding: 12px;
            background: #282A39;
            border-radius: 8px;
            width: 100%;
            font-weight: 700;
            display: flex;
            margin-bottom: 10px;


            &_content {
                display: flex;
                flex-wrap: wrap;
                margin-top: 20px;

                & a {
                    margin: 0 0 1rem;
                    font-size: 14px;
                    line-height: 1.25rem;
                    font-weight: 400;
                    color: #A6ABB0;
                    flex-basis: 50%;
                    padding: 0 2rem 0 0;
                    margin-bottom: 2rem;
                    line-height: 1.5rem;
                }
            }
        }
    }

    &__links {
        margin: 0 0 1rem;
        display: none;
        flex-direction: column;
        align-items: flex-start;

        & span {
            font-size: 20px;
            line-height: 1.5rem;
            font-weight: 700;
            margin-bottom: 2rem;
            color: #FFFFFF;
        }

        & div {
            flex-direction: column;
            display: flex;

            & a {
                margin: 0 0 1rem;
                font-size: 14px;
                line-height: 1.25rem;
                font-weight: 400;
                color: #A6ABB0;

                &:hover {
                    color: #FFFFFF;
                    text-decoration: underline;
                }
            }
        }

        &_mobile {
            padding: 12px;
            background: #282A39;
            border-radius: 8px;
            width: 100%;
            font-weight: 700;
            display: flex;

            &_content {
                display: flex;
                flex-wrap: wrap;
                margin-top: 20px;

                & a {
                    margin: 0 0 1rem;
                    font-size: 14px;
                    line-height: 1.25rem;
                    font-weight: 400;
                    color: #A6ABB0;
                    flex-basis: 50%;
                    padding: 0 2rem 0 0;
                    margin-bottom: 2rem;
                }
            }
        }
    }

    &__help {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    &__support {
        display: flex;
        flex-direction: row;
        gap: 14px;
        align-items: center;
        color: #FFFFFF;
        background: #32333e;
        border-radius: 20px;
        padding: 4px 10px;
        width: 120px;
        height: 48px;
        flex: 1 1 0;

        &:hover {
            background: #272730;
        }
    }

    &__flag {
        display: flex;
        flex-direction: row;
        gap: 14px;
        align-items: center;
        color: #FFFFFF;
        background: #32333e;
        border-radius: 20px;
        padding: 4px 14px;
        width: 100px;
        height: 48px;

        &:hover {
            background: #272730;
        }

        & span {
            font-size: 14px;
        }

        &_icon {
            position: relative;
            overflow: hidden;
            margin-right: 0.5rem;
            border-radius: 50%;
            width: 32px;
            height: 32px;
            display: flex;

            & svg {
                position: absolute;
                top: 50%;
                left: 50%;
                height: 100%;
                transform: translate(-50%, -50%);
            }
        }
    }

    &__partner {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        color: #FFFFFF;
        background: #32333e;
        border-radius: 20px;
        padding: 4px 14px;
        width: 100%;
        height: 48px;

        &:hover {
            background: #272730;
        }
    }

    &__text {
        margin: 30px 0;

        & p {
            font-size: 14px;
            line-height: 14px;
            font-weight: 400;
            margin-bottom: 3rem;
            color: #7f868e;
        }

        & span {
            font-size: 12px;
            font-weight: 400;
            text-align: center;
            color: #7f868e;
            width: 100%;
            justify-content: center;
            display: flex;
        }
    }

    &__copyright {
        width: 100%;
        height: 84px;
        padding: 16px 0;
        color: #FFFFFF;
        background: #000000;
        display: flex;
        justify-content: center;
        align-items: center;

        & p {
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            text-align: center;
        }
    }

    &__mobile {
        margin-bottom: 20px;
        order: 3;
    }
}