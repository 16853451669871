.c-text {

	&-container {
		background-color: white;
		padding: 10px;
		border-radius: 10px;
	}

	margin-bottom: 25px;

	& img {
		margin: auto;
	}

	& p {
		margin-bottom: 10px;
		font-size: $font-size-base;
	}

	& ul {
		padding-left: 25px;
		list-style-type: disc;
		font-size: $font-size-base;
		margin-bottom: 15px;
	}

	& ol {
		padding-left: 25px;
		list-style-type: decimal;
		font-size: $font-size-base;
		margin-bottom: 15px;
	}

	& a:not([class]) {
		color: $link-hover;
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	.o-text-image {

		&_right {
			float: right;
			max-width: 40vw;
		}

		&_left {
			float: left;
			max-width: 40vw;
		}
	}
}


.c-table-of-content {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	width: 100%;
	padding: 16px 40px;
	list-style: decimal;
	flex-direction: column;



	& a:not([class]) {
		background: linear-gradient(#1245FF 75%, #1245FF 100%) right bottom;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-weight: bold;

		&:hover {
			text-decoration: underline;
		}
	}

	&__wrapper {
		background-color: $white;
		border: 1px solid $white;
		border-radius: 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 10px;
		margin-bottom: 40px;

		& summary {
			color: #1245FF;
			font-weight: bold;
			cursor: pointer;
		}
	}

	&__item {
		width: 100%;
		margin-bottom: 10px;


		&:nth-child(odd) {
			padding-right: 25px;
		}
	}

}