.c-header {
	box-shadow: 0 0 1px rgba(0, 0, 0, 0.04), 0 0 2px rgba(0, 0, 0, 0.06), 0 4px 8px rgba(0, 0, 0, 0.04);
	position: sticky;
	top: 0;
	width: 100%;
	background: #FFFFFF;
	z-index: 11;
	display: flex;
	flex-direction: row;
	align-items: center;

	&__content {
		display: flex;
		flex-wrap: nowrap;
	}

	&__nav {
		display: none;
	}

	&__burger {
		display: block;
		padding: 14px;
		text-decoration: none;
		color: #000000;
		font-size: 1.5rem;
		width: 40px;
		height: 40px;
		background-color: #FFFFFF;

		&:hover {
			color: antiquewhite;
		}

		&__menu {
			z-index: 2;
			position: relative;
		}

		&__menu__title {
			display: none;
		}

		&__close {
			width: 70px;
			height: 60px;
			background: #44515c;
			font-size: 1.8rem;
			color: #fff;
			align-items: center;
			justify-content: center;
			display: flex;
		}
	}

	& .o-btn_green_animation {
		display: none;
	}

	& .o-btn_blue {
		display: none;
	}


	&__buttons {
		color: #fff;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 8px;
		margin-left: auto;
		padding-right: 10px;
	}

	&__logo {
		margin-left: auto;
	}
}

@keyframes fly {
	0% {
		transform: translate(0);
	}

	100% {
		transform: translate(100%);
	}
}