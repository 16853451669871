.c-slot {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0 5px;
	margin-bottom: 15px;
	float: left;
	width: calc(100% / 2);
	height: auto;

	&s {
		margin-left: -5px;
		margin-right: -5px;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		justify-content: center;
		align-items: stretch;
		flex-direction: row;
		flex-wrap: wrap;
		margin-bottom: 25px;
	}

	&__icon {
		height: 100%;
		position: relative;
		width: 100%;
		border-radius: 10px;
		overflow: hidden;

		& img {
			width: 100%;
			height: 100%;
			border-radius: 4px 4px 0 0;
			overflow: hidden;
		}
	}

	&__new {
		position: absolute;
		right: 15px;
		top: 10px;
		max-width: 40px;
		margin-left: auto;
		background: #ff5100;
		color: $black;
		padding: 0 6px;
		margin-bottom: 2px;
		line-height: 26px;
		font-size: 1.1rem;
		border-radius: 3px;
		font-weight: 700;
		text-transform: uppercase;
		text-align: center;
	}

	&__name {
		display: flex;
		align-items: center;
		margin: 8px 0 0 0;
		line-height: 1.875rem;
		font-size: 14px;
		color: black;
		width: 100%;
		color: $black;
		text-align: center;
		overflow: hidden;
		padding-top: 11px;
		padding-bottom: 11px;
		font-weight: 500;
		text-overflow: ellipsis;
		white-space: nowrap;
		letter-spacing: .5px;
		padding-left: 7px;
		padding-right: 7px;
	}

	&:hover &__overflow {
		opacity: 1;
	}

	&__overflow {
		opacity: 0;
		display: block;
		background: linear-gradient(0deg, #15161f 0%, rgba(20, 23, 34, 0.72) 46.67%, rgba(39, 43, 65, 0) 100%);
		transition: 200ms;
		text-align: center;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: space-evenly;
		flex-direction: row;
		z-index: 10;
		gap: 10px;
	}

	&__overflow-play {
		width: 64px;
		height: 64px;
		margin-bottom: 12px;
		border-radius: 50%;
		transition: all .3s linear;
		overflow: hidden;
		text-overflow: ellipsis;
		display: inline-block;
		text-align: center;
		text-decoration: none;
		font-size: 1.4rem;

		&:hover {
			transform: scale(1.03);
		}
	}

	&__overflow-demo {
		font-size: 1.4rem;
		width: 120px;
		height: 40px;
		margin-bottom: 6px;
		color: $white;
		font-weight: 700;
		line-height: 38px;
		border-radius: 60px;
		background-color: transparent;
		transition: background-color .3s linear;
		border: 1px solid #ffffff;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: center;
		text-decoration: none;
		display: none;

		&:hover {
			color: #000000;
			background: #dbdbdb;
		}
	}

	& .o-btn_primary {
		width: 116px;
		height: 40px;
		margin-bottom: 6px;
		color: #ffffff;
	}
}


@media screen and (min-width: 600px) {
	.c-slot {
		width: calc(100% / 3);
	}
}

// @media screen and (min-width: 768px) {}

@media screen and (min-width: 992px) {
	.c-slot {
		width: calc(100% / 4);
	}
}

@media screen and (min-width: 1200px) {
	.c-slot {
		width: calc(100% / 5);
	}
}