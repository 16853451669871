.c-tournament {
    &s {
        display: flex;
        flex-direction: column;
        gap: 10px;
        scrollbar-width: none;
        overflow: hidden;
        overflow-x: auto;
        margin-bottom: 30px;

        & :nth-child(3) {
            .c-tournament_card_body_timer {
                margin-top: 180px;
            }
            .c-tournament_card_body_info {
                gap: 40px;
            }
        }
        &__content {
            flex-direction: row;
            display: flex;
            gap: 10px;
        }
    }

    &_card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 300px;
        padding: 0.5rem 1.25rem;
        color: #FFFFFF;
        background: linear-gradient(150deg, #6C8DFF 0%, #1044FF 100%);
        border-radius: 0.5rem;
        width: 100%;
        height: 490px;

        &_header {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 0.5rem;
            z-index: 1;
        }

        &_title {
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
        }

        &_active_text {
            color: #ffe500;
            font-weight: 600;

            &_big {
                color: #ffe500;
                font-size: 32px;
                font-weight: 600;
                margin-bottom: 20px;
            }
        }

        &_body {
            &_prizepool {
                margin-bottom: 0;
                line-height: 3rem;
                font-size: 36px;
                font-weight: 700;
                text-align: center;
                white-space: nowrap;
                color: #ffe500;
                display: flex;
                justify-content: center;
            }

            &_info {
                display: flex;
                flex-direction: column;

                & span:nth-child(4) {
                    margin-bottom: 30px;
                }
            }

            &_description {
                font-size: 1.5rem;
                min-height: 3rem;
                line-height: 2rem;
                font-weight: 400;
                margin: 1rem 0;

                &_bold {
                    font-size: 16px;
                    line-height: 1.5rem;
                    font-weight: 700;
                    min-height: 3rem;
                    margin-bottom: 0.5rem;
                }
            }

            &_score {
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
                text-transform: uppercase;
                margin-bottom: 4px;
            }

            &_trophies {
                display: flex;
                flex-direction: row;
                gap: 20px;

                & svg:nth-child(1) {
                    fill: #ffe500;
                }

                & svg:nth-child(2) {
                    fill: #5d7eff
                }

                & svg:nth-child(3) {
                    fill: #5d7eff
                }
            }

            &_icon-plus {
                position: absolute;
                bottom: 0;
                right: 3rem;
                width: 8.5rem;
                height: 8.5rem;
                transform: translateY(-50%);
                border-width: initial;
                pointer-events: none;

                & span {
                    font-size: 7rem;
                    translate: 100%;
                    position: absolute;
                    font-weight: 900;
                    top: -1px;
                    left: -4px;
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: calc(100% - 1.5rem);
                    height: calc(100% - 1.5rem);
                    opacity: 0.3;
                    background: none;
                    border-width: 12px;
                    border-style: solid;
                    border-color: rgb(255, 255, 255);
                    border-image: initial;
                    border-radius: 50%;
                    width: 104px;
                    height: 104px;
                }
            }

            &_timer {
                display: flex;
                flex-direction: column;
                width: 12rem;
                margin: 0;
                line-height: 1;
                text-align: center;
                margin-top: 50px;

                &_title {
                    font-size: 16px;
                    line-height: 1.5rem;
                    font-weight: 400;
                    margin-bottom: 10px;
                }

                &_number {
                    font-size: 20px;
                    font-weight: bold;

                    &s {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                    }
                }

                &_content {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                }
            }
        }
    }
}