@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Italic.eot');
    src: url('../fonts/Roboto/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto/Roboto-Italic.woff2') format('woff2'),
        url('../fonts/Roboto/Roboto-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Bold.eot');
    src: url('../fonts/Roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto/Roboto-Bold.woff2') format('woff2'),
        url('../fonts/Roboto/Roboto-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Black.eot');
    src: url('../fonts/Roboto/Roboto-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto/Roboto-Black.woff2') format('woff2'),
        url('../fonts/Roboto/Roboto-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Medium.eot');
    src: url('../fonts/Roboto/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto/Roboto-Medium.woff2') format('woff2'),
        url('../fonts/Roboto/Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-BlackItalic.eot');
    src: url('../fonts/Roboto/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto/Roboto-BlackItalic.woff2') format('woff2'),
        url('../fonts/Roboto/Roboto-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-BoldItalic.eot');
    src: url('../fonts/Roboto/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto/Roboto-BoldItalic.woff2') format('woff2'),
        url('../fonts/Roboto/Roboto-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Light.eot');
    src: url('../fonts/Roboto/Roboto-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto/Roboto-Light.woff2') format('woff2'),
        url('../fonts/Roboto/Roboto-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-MediumItalic.eot');
    src: url('../fonts/Roboto/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto/Roboto-MediumItalic.woff2') format('woff2'),
        url('../fonts/Roboto/Roboto-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-LightItalic.eot');
    src: url('../fonts/Roboto/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto/Roboto-LightItalic.woff2') format('woff2'),
        url('../fonts/Roboto/Roboto-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-ThinItalic.eot');
    src: url('../fonts/Roboto/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto/Roboto-ThinItalic.woff2') format('woff2'),
        url('../fonts/Roboto/Roboto-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Regular.eot');
    src: url('../fonts/Roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
        url('../fonts/Roboto/Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto/Roboto-Thin.eot');
    src: url('../fonts/Roboto/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto/Roboto-Thin.woff2') format('woff2'),
        url('../fonts/Roboto/Roboto-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

