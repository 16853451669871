table {
	& a:not([class]) {
		color: $link-hover;
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	width: 100%;
	border-spacing: 0;
	border-collapse: collapse;

	& th {
		background: white;
	}

	& th,
	& td {
		padding: 7px 10px;
		border: 2px solid #1245FF;
		min-width: 120px;
		text-align: center;
	}
}

.c-table {
	&__wrapper {
		margin-bottom: 15px;
		background-color: white;
		padding: 0 10px;
		&::-webkit-scrollbar {
			display: none;
		}

		width: 100%;
		overflow-x: scroll;
	}
}