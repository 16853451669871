@charset "UTF-8";
*, *::before, *::after {
  box-sizing: border-box;
}

ul, ol {
  padding: 0;
}

body, h1, h2, h3, h4, h5, h6, p, ul, ol, li, figure, figcaption, blockquote, dl, dd {
  margin: 0;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

ul, ol {
  list-style: none;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Italic.eot");
  src: url("../fonts/Roboto/Roboto-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto/Roboto-Italic.woff2") format("woff2"), url("../fonts/Roboto/Roboto-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Bold.eot");
  src: url("../fonts/Roboto/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto/Roboto-Bold.woff2") format("woff2"), url("../fonts/Roboto/Roboto-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Black.eot");
  src: url("../fonts/Roboto/Roboto-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto/Roboto-Black.woff2") format("woff2"), url("../fonts/Roboto/Roboto-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Medium.eot");
  src: url("../fonts/Roboto/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto/Roboto-Medium.woff2") format("woff2"), url("../fonts/Roboto/Roboto-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-BlackItalic.eot");
  src: url("../fonts/Roboto/Roboto-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto/Roboto-BlackItalic.woff2") format("woff2"), url("../fonts/Roboto/Roboto-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-BoldItalic.eot");
  src: url("../fonts/Roboto/Roboto-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto/Roboto-BoldItalic.woff2") format("woff2"), url("../fonts/Roboto/Roboto-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Light.eot");
  src: url("../fonts/Roboto/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto/Roboto-Light.woff2") format("woff2"), url("../fonts/Roboto/Roboto-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-MediumItalic.eot");
  src: url("../fonts/Roboto/Roboto-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto/Roboto-MediumItalic.woff2") format("woff2"), url("../fonts/Roboto/Roboto-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-LightItalic.eot");
  src: url("../fonts/Roboto/Roboto-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto/Roboto-LightItalic.woff2") format("woff2"), url("../fonts/Roboto/Roboto-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-ThinItalic.eot");
  src: url("../fonts/Roboto/Roboto-ThinItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto/Roboto-ThinItalic.woff2") format("woff2"), url("../fonts/Roboto/Roboto-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Regular.eot");
  src: url("../fonts/Roboto/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto/Roboto-Regular.woff2") format("woff2"), url("../fonts/Roboto/Roboto-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Thin.eot");
  src: url("../fonts/Roboto/Roboto-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto/Roboto-Thin.woff2") format("woff2"), url("../fonts/Roboto/Roboto-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@media screen and (min-width: 576px) {
  .o-container {
    max-width: 540px;
  }
}
@media screen and (min-width: 768px) {
  .o-container {
    max-width: 720px;
  }
}
@media screen and (min-width: 992px) {
  .o-container {
    max-width: 960px;
  }
}
@media screen and (min-width: 1200px) {
  .o-container {
    max-width: 1140px;
  }
}
.o-container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 10px;
}

.o-wrapper {
  padding: 0 15px;
}

body {
  min-height: 100vh;
}

.o-list {
  list-style: none;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

*:focus {
  outline: none !important;
}

html {
  font-size: 62.5%;
  scroll-padding-top: 70px;
}

body {
  color: #000;
  font-family: "Roboto", sans-serif;
  background: #F5F5F5;
  font-size: 1.6rem;
}

.svg-none {
  display: none;
}

.o-text-bold {
  font-weight: bold;
}
.o-text-center {
  text-align: center;
}
.o-text-up {
  text-transform: uppercase;
}

img {
  max-width: 100%;
  height: auto;
}

button,
a {
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.2s;
  text-decoration: none;
  color: inherit;
}
button picture img:hover,
a picture img:hover {
  transform: scale(1.02);
}

h1,
.h1 {
  font-size: 3rem;
}

h2,
.h2 {
  font-size: 2.8rem;
}

h3,
.h3 {
  font-size: 2.5rem;
}

h4,
.h4 {
  font-size: 2.2rem;
}

h5,
.h5 {
  font-size: 1.9rem;
}

h6,
.h6 {
  font-size: 1.6rem;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: 0.2s;
}

.o-btn {
  background: transparent;
  overflow: hidden;
  width: 100%;
  min-height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem;
  line-height: 1rem;
  font-weight: 500;
  text-align: center;
  border-radius: 100px;
  height: 48px;
  max-width: 190px;
  font-size: 14px;
}
.o-btn_green {
  background: linear-gradient(-45deg, #2EC417 0%, #2EC417 25%, #77d968 50%, #2EC417 75%, #2EC417 100%) left top;
}
.o-btn_green:hover {
  background: linear-gradient(16deg, #19bd00 0%, #1bc601 25%, #53e73c 50%, #77f065 75%, #7fff6b 100%) right bottom;
}
.o-btn_green_animation {
  position: relative;
  background: linear-gradient(16deg, #2EC417 0%, #2EC417 25%, #77d968 50%, #2EC417 75%, #2EC417 100%) right bottom;
  overflow: hidden;
  height: 3.5rem;
  padding: 0px 30px;
  width: 116px;
  height: 40px;
}
.o-btn_green_animation::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 400%;
  height: 100%;
  background: url(../img/button-animation.svg) no-repeat left 0 top 0;
  background-size: contain;
  animation: fly 2s linear 1s infinite;
}
.o-btn_green_animation:hover {
  background: linear-gradient(16deg, #19bd00 0%, #1bc601 25%, #53e73c 50%, #77f065 75%, #7fff6b 100%) right bottom;
}
.o-btn_white {
  background: linear-gradient(-45deg, #F3F3F3 0%, #F3F3F3 25%, #e7e7e7 50%, #F3F3F3 75%, #F3F3F3 100%) right bottom;
  color: black;
}
.o-btn_white:hover {
  background: linear-gradient(45deg, #dfdfdf 0%, #dfdfdf 25%, #dbdbdb 50%, #dfdfdf 75%, #dfdfdf 100%) right bottom;
}
.o-btn_blue {
  position: relative;
  width: 70px;
  height: 40px;
  background: linear-gradient(-45deg, #1245FF 0%, #1245FF 25%, #6586ff 50%, #1245FF 75%, #1245FF 100%) right bottom;
}
.o-btn_blue::before {
  content: "";
  position: absolute;
  top: 0;
  width: 200%;
  height: 100%;
  background-size: 100% 100%;
  transition: 0.2s;
  z-index: -1;
  left: -100%;
}
.o-btn_blue:hover {
  background: linear-gradient(-45deg, #3762ff 0%, #315dff 25%, #6586ff 50%, #0436ea 75%, #0037ff 100%) right bottom;
}
.o-btn_blue_animation {
  position: relative;
  background: linear-gradient(-45deg, #1245FF 0%, #1245FF 25%, #6586ff 50%, #1245FF 75%, #1245FF 100%) right bottom;
  overflow: hidden;
  height: 3.5rem;
  padding: 0px 30px;
  color: #fff;
  width: 116px;
  height: 40px;
}
.o-btn_blue_animation::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 400%;
  height: 100%;
  background: url(../img/button-animation.svg) no-repeat left 0 top 0;
  background-size: contain;
  animation: fly 2s linear 1s infinite;
}
.o-btn_blue_animation:hover {
  background: linear-gradient(-45deg, #3762ff 0%, #315dff 25%, #6586ff 50%, #0436ea 75%, #0037ff 100%) right bottom;
}
.o-btn_search {
  position: relative;
  width: 40px;
  height: 40px;
}
.o-btn_search::before {
  content: "";
  position: absolute;
  top: 0;
  width: 200%;
  height: 100%;
  background-size: 100% 100%;
  transition: 0.2s;
  z-index: -1;
  right: -100%;
  background: linear-gradient(-45deg, #F3F3F3 0%, #F3F3F3 25%, #e7e7e7 50%, #F3F3F3 75%, #F3F3F3 100%) right bottom;
}
.o-btn_primary {
  position: relative;
  background: linear-gradient(-45deg, #1245FF 0%, #1245FF 25%, #6586ff 50%, #1245FF 75%, #1245FF 100%) right bottom;
}
.o-btn_primary::before {
  content: "";
  position: absolute;
  top: 0;
  width: 200%;
  height: 100%;
  background-size: 100% 100%;
  transition: 0.2s;
  z-index: -1;
  left: -100%;
}
.o-btn_primary:hover {
  background: linear-gradient(-45deg, #3762ff 0%, #315dff 25%, #6586ff 50%, #0436ea 75%, #0037ff 100%) right bottom;
}

.c-header {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.04), 0 0 2px rgba(0, 0, 0, 0.06), 0 4px 8px rgba(0, 0, 0, 0.04);
  position: sticky;
  top: 0;
  width: 100%;
  background: #FFFFFF;
  z-index: 11;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.c-header__content {
  display: flex;
  flex-wrap: nowrap;
}
.c-header__nav {
  display: none;
}
.c-header__burger {
  display: block;
  padding: 14px;
  text-decoration: none;
  color: #000000;
  font-size: 1.5rem;
  width: 40px;
  height: 40px;
  background-color: #FFFFFF;
}
.c-header__burger:hover {
  color: antiquewhite;
}
.c-header__burger__menu {
  z-index: 2;
  position: relative;
}
.c-header__burger__menu__title {
  display: none;
}
.c-header__burger__close {
  width: 70px;
  height: 60px;
  background: #44515c;
  font-size: 1.8rem;
  color: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
}
.c-header .o-btn_green_animation {
  display: none;
}
.c-header .o-btn_blue {
  display: none;
}
.c-header__buttons {
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-left: auto;
  padding-right: 10px;
}
.c-header__logo {
  margin-left: auto;
}

@keyframes fly {
  0% {
    transform: translate(0);
  }
  100% {
    transform: translate(100%);
  }
}
.c-banner {
  margin-bottom: 30px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.04), 0 0 2px rgba(0, 0, 0, 0.06), 0 4px 8px rgba(0, 0, 0, 0.04);
}
.c-banner .o-btn_primary {
  width: 48px;
  height: 48px;
}
.c-banner__text_block {
  flex-direction: row;
  align-items: center;
  min-height: 106px;
  position: relative;
  top: unset;
  left: unset;
  flex-grow: 1;
  width: auto;
  max-width: none;
  height: auto;
  padding: 1rem 2rem;
  color: #000000;
  background: #FFFFFF;
  border: none;
  transform: unset;
  box-sizing: content-box;
  border-radius: 0 0 1rem 1rem;
  display: flex;
}
.c-banner__text_row {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0 0;
  margin-bottom: 0.5rem;
}
.c-banner__text_mobile {
  width: min-content;
}
.c-banner__text_mobile strong {
  font-size: 28px;
  font-weight: bold;
}
.c-banner__text_mobile span {
  color: #52575c;
  font-size: 16px;
}
.c-banner__text_desktop {
  display: none;
}

.c-aside {
  background: linear-gradient(135deg, #B2C3FF 0%, #6385FF 28.38%, #1044FF 100%);
  border-radius: 0 1rem 0 0;
  position: fixed;
  left: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  transition: 0.2s;
  transform: translateX(-100%);
  z-index: -1;
}
.c-aside__content {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 68px);
  padding: 1.5rem 2rem 2rem 2rem;
  color: #FFFFFF;
}
.c-aside__top {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  padding: 0.75rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
}
.c-aside__line {
  width: calc(100% - 48px);
  margin: 0 auto;
  border-color: #FFFFFF;
  opacity: 0.2;
  border-top: 1px solid #D1D3D6;
}
.c-aside__caption {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  font-size: 16px;
}
.c-aside__caption_green {
  line-height: 1.25rem;
  font-size: 18px;
  font-weight: 900;
  color: #4EE736;
}
.c-aside__country-icon {
  transform: rotate(-90deg);
  margin-left: auto;
}
.c-aside__menu_buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
}
.c-aside__menu_buttons a:hover {
  background: linear-gradient(172deg, rgba(250, 250, 250, 0.23) 0%, rgba(255, 255, 255, 0.24) 100%);
}
.c-aside__nav {
  margin: 20px 0 0 16px;
  display: flex;
  flex-direction: column;
}
.c-aside__nav_item {
  font-size: 18px;
  line-height: 1.5rem;
  font-weight: 600;
  color: inherit;
  display: flex;
  flex: 1;
  align-items: center;
  margin-bottom: 30px;
  gap: 20px;
}
.c-aside__menu_bonus {
  position: relative;
  margin-left: -1.5rem;
  margin-bottom: 30px;
}
.c-aside__menu_bonus img {
  width: 252px;
}
.c-aside__menu_bonus .c-aside__caption {
  flex-direction: column;
  gap: unset;
}
.c-aside__menu_bonus .c-aside__line {
  width: 100%;
}
.c-aside__menu_bonus_download {
  position: absolute;
  top: 7px;
  right: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: 182px;
  height: 74px;
  padding: 0rem 2rem 0.5rem 0rem;
  background: rgba(255, 255, 255, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0.5rem;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.c-aside__menu_bonus_download::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 100%;
  font-size: 1.5rem;
  color: #1245FF;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
.c-aside__menu_bonus_caption {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  text-align: center;
}
.c-aside__menu_support {
  position: relative;
  display: flex;
  align-items: center;
  width: 190px;
  height: 48px;
  padding: 0.5rem;
  color: #FFFFFF;
  background: linear-gradient(123deg, rgba(250, 250, 250, 0.1) 0%, rgba(235, 235, 235, 0.1) 100%);
  border-radius: 1.5rem;
  cursor: pointer;
}
.c-aside__menu_support::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(123deg, rgba(250, 250, 250, 0.05) 0%, rgba(235, 235, 235, 0.05) 100%);
  border-radius: 1.5rem;
  transition: 0.2s;
  opacity: 0;
}
.c-aside__menu_country {
  position: relative;
  display: flex;
  align-items: center;
  width: 190px;
  height: 48px;
  padding: 0.5rem;
  color: #FFFFFF;
  background: linear-gradient(123deg, rgba(250, 250, 250, 0.1) 0%, rgba(235, 235, 235, 0.1) 100%);
  border-radius: 1.5rem;
  cursor: pointer;
}
.c-aside__menu_country::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(123deg, rgba(250, 250, 250, 0.05) 0%, rgba(235, 235, 235, 0.05) 100%);
  border-radius: 1.5rem;
  transition: 0.2s;
  opacity: 0;
}
.c-aside__menu_icon {
  position: relative;
  overflow: hidden;
  width: 32px;
  height: 32px;
  margin-right: 0.5rem;
  border-radius: 50%;
}
.c-aside__menu_icon-chat {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  box-shadow: inset 0 0 0 1px #FFFFFF;
}
.c-aside__menu_icon-flag {
  position: relative;
  overflow: hidden;
  margin-right: 0.5rem;
  border-radius: 50%;
}
.c-aside__menu_icon-flag svg {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  transform: translate(-50%, -50%);
}

.is-aside-open {
  max-width: 260px;
  transform: translate(0);
  z-index: 1000;
}

.c-aside__close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #FFFFFF;
  padding: 12px;
}

.c-header__burger__menu:focus {
  display: none;
}

.c-comment {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px solid #1245FF;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}
.c-comment a:not([class]) {
  color: #ffeeaa;
  text-decoration: none;
}
.c-comment a:not([class]):hover, .c-comment a:not([class]):focus {
  text-decoration: underline;
}
.c-comments {
  margin-bottom: 30px;
}
.c-comment__name {
  width: 100%;
  text-align: left;
  font-size: 2rem;
}
.c-comment__content {
  padding: 10px;
  display: -webkit-inline-box;
  display: inline-flex;
  text-align: left;
  width: 100%;
}
.c-comment .o-btn {
  margin-left: auto;
}

.c-faq {
  margin-bottom: 30px;
}
.c-faq a:not([class]) {
  color: #ffeeaa;
  text-decoration: none;
}
.c-faq a:not([class]):hover, .c-faq a:not([class]):focus {
  text-decoration: underline;
}
.c-faq details[open] summary {
  border-radius: 4px 4px 0 0;
}
.c-faq__item {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin-bottom: 10px;
}
.c-faq__title {
  cursor: pointer;
  padding: 7px;
  padding-left: 30px;
  background: linear-gradient(#1245FF 75%, #1245FF 100%) right bottom;
  color: #fff;
  font-weight: 600;
  font-size: 1.6rem;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 4px;
  word-break: break-all;
  position: relative;
}
.c-faq__title::-webkit-details-marker {
  display: none;
}
.c-faq__title:before {
  content: "►";
  position: absolute;
  display: -webkit-box;
  display: flex;
  left: 10px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  color: #ffffff;
  width: 10px;
  height: 10px;
}
.c-faq__content {
  padding: 10px 15px;
  border: 2px solid #1245FF;
  border-top: 0;
  border-radius: 0 0 4px 4px;
}

.c-text {
  margin-bottom: 25px;
}
.c-text-container {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
}
.c-text img {
  margin: auto;
}
.c-text p {
  margin-bottom: 10px;
  font-size: 1.6rem;
}
.c-text ul {
  padding-left: 25px;
  list-style-type: disc;
  font-size: 1.6rem;
  margin-bottom: 15px;
}
.c-text ol {
  padding-left: 25px;
  list-style-type: decimal;
  font-size: 1.6rem;
  margin-bottom: 15px;
}
.c-text a:not([class]) {
  color: #ffeeaa;
  text-decoration: none;
}
.c-text a:not([class]):hover, .c-text a:not([class]):focus {
  text-decoration: underline;
}
.c-text .o-text-image_right {
  float: right;
  max-width: 40vw;
}
.c-text .o-text-image_left {
  float: left;
  max-width: 40vw;
}

.c-table-of-content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  width: 100%;
  padding: 16px 40px;
  list-style: decimal;
  flex-direction: column;
}
.c-table-of-content a:not([class]) {
  background: linear-gradient(#1245FF 75%, #1245FF 100%) right bottom;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}
.c-table-of-content a:not([class]):hover {
  text-decoration: underline;
}
.c-table-of-content__wrapper {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin-bottom: 40px;
}
.c-table-of-content__wrapper summary {
  color: #1245FF;
  font-weight: bold;
  cursor: pointer;
}
.c-table-of-content__item {
  width: 100%;
  margin-bottom: 10px;
}
.c-table-of-content__item:nth-child(odd) {
  padding-right: 25px;
}

.c-slot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  margin-bottom: 15px;
  float: left;
  width: 50%;
  height: auto;
}
.c-slots {
  margin-left: -5px;
  margin-right: -5px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 25px;
}
.c-slot__icon {
  height: 100%;
  position: relative;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.c-slot__icon img {
  width: 100%;
  height: 100%;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
}
.c-slot__new {
  position: absolute;
  right: 15px;
  top: 10px;
  max-width: 40px;
  margin-left: auto;
  background: #ff5100;
  color: #000000;
  padding: 0 6px;
  margin-bottom: 2px;
  line-height: 26px;
  font-size: 1.1rem;
  border-radius: 3px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}
.c-slot__name {
  display: flex;
  align-items: center;
  margin: 8px 0 0 0;
  line-height: 1.875rem;
  font-size: 14px;
  color: black;
  width: 100%;
  color: #000000;
  text-align: center;
  overflow: hidden;
  padding-top: 11px;
  padding-bottom: 11px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  letter-spacing: 0.5px;
  padding-left: 7px;
  padding-right: 7px;
}
.c-slot:hover .c-slot__overflow {
  opacity: 1;
}
.c-slot__overflow {
  opacity: 0;
  display: block;
  background: linear-gradient(0deg, #15161f 0%, rgba(20, 23, 34, 0.72) 46.67%, rgba(39, 43, 65, 0) 100%);
  transition: 200ms;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  flex-direction: row;
  z-index: 10;
  gap: 10px;
}
.c-slot__overflow-play {
  width: 64px;
  height: 64px;
  margin-bottom: 12px;
  border-radius: 50%;
  transition: all 0.3s linear;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 1.4rem;
}
.c-slot__overflow-play:hover {
  transform: scale(1.03);
}
.c-slot__overflow-demo {
  font-size: 1.4rem;
  width: 120px;
  height: 40px;
  margin-bottom: 6px;
  color: #ffffff;
  font-weight: 700;
  line-height: 38px;
  border-radius: 60px;
  background-color: transparent;
  transition: background-color 0.3s linear;
  border: 1px solid #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  text-decoration: none;
  display: none;
}
.c-slot__overflow-demo:hover {
  color: #000000;
  background: #dbdbdb;
}
.c-slot .o-btn_primary {
  width: 116px;
  height: 40px;
  margin-bottom: 6px;
  color: #ffffff;
}

@media screen and (min-width: 600px) {
  .c-slot {
    width: 33.3333333333%;
  }
}
@media screen and (min-width: 992px) {
  .c-slot {
    width: 25%;
  }
}
@media screen and (min-width: 1200px) {
  .c-slot {
    width: 20%;
  }
}
.c-jackpot {
  margin: 4rem 0;
}
.c-jackpot_divider_top {
  position: absolute;
  left: 0;
  top: -0.9px;
  width: 100%;
  height: 0.5rem;
  margin: 0;
  background: url(../img/jackpot-arrow.svg);
  z-index: 1;
}
.c-jackpot_divider_bottom {
  position: absolute;
  left: 0;
  bottom: -0.9px;
  width: 100%;
  height: 0.5rem;
  margin: 0;
  transform: rotate(180deg);
  background: url(../img/jackpot-arrow.svg);
}
.c-jackpot_content {
  padding: 2rem 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
  display: flex;
  align-items: center;
  background: center/cover url(../img/jackpot-banner.webp);
  opacity: 1;
}
.c-jackpot_title {
  margin: 0 0 4px 0;
  color: #000000;
  font-size: 24px;
  line-height: 1.75rem;
  font-weight: 700;
  margin-bottom: 20px;
  display: flex;
}
.c-jackpot_money_content {
  padding: 2rem 0.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem 0;
}
.c-jackpot_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 16.25rem;
  min-width: 30rem;
  text-align: center;
  color: #FFFFFF;
  background: rgba(0, 8, 36, 0.5);
  border: 2px solid #2c5aff;
  border-radius: 1rem;
}
.c-jackpot_block_title {
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;
}
.c-jackpot_block_money {
  margin-top: 0.5rem;
  font-size: 32px;
  line-height: 1;
  font-weight: 700;
}
.c-jackpot_block .o-btn_blue {
  margin: 4px 0 -28px;
  font-size: 16px;
  line-height: 1.5rem;
  font-weight: 500;
  min-height: 56px;
  padding: 0.25rem 1.5rem;
  min-width: 140px;
}
.c-jackpot_games {
  position: relative;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: var(--ds2-rows, unset);
  grid-auto-flow: column;
  grid-auto-columns: unset;
  grid-column: 2/3;
  gap: 0;
  overflow-x: auto;
  overflow-y: hidden;
  width: auto;
  cursor: pointer;
  z-index: 0;
  scrollbar-width: none;
}
.c-jackpot_games_item {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 60%;
  padding: 0.5rem;
  background: linear-gradient(0deg, #15161f 0%, rgba(20, 23, 34, 0.72) 46.67%, rgba(39, 43, 65, 0) 100%);
  border-radius: 8px;
  transition: 0.2s;
  opacity: 0;
}
.c-jackpot_games_item:hover {
  opacity: 1;
}
.c-jackpot_games_overlay {
  position: relative;
}
.c-jackpot_games_prize {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.25rem 0.75rem;
  text-align: center;
  color: #FFFFFF;
  background: linear-gradient(150deg, #6C8DFF 0%, #1044FF 100%);
  border-radius: 0 0 8px 8px;
}
.c-jackpot_bird {
  display: none;
}
.c-jackpot .c-slot__overflow {
  padding: 10px;
}
.c-jackpot .c-slot__name {
  display: flex;
  align-items: center;
  margin: 8px 0 0 0;
  line-height: 1.875rem;
  font-size: 14px;
  color: #FFFFFF;
}
.c-jackpot .c-slot__jackpot {
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.25rem 0.75rem;
  text-align: center;
  color: #FFFFFF;
  background: linear-gradient(150deg, #6C8DFF 0%, #1044FF 100%);
  border-radius: 0 0 8px 8px;
}
.c-jackpot .c-slots {
  position: relative;
  top: 46px;
}

.c-tournaments {
  display: flex;
  flex-direction: column;
  gap: 10px;
  scrollbar-width: none;
  overflow: hidden;
  overflow-x: auto;
  margin-bottom: 30px;
}
.c-tournaments :nth-child(3) .c-tournament_card_body_timer {
  margin-top: 180px;
}
.c-tournaments :nth-child(3) .c-tournament_card_body_info {
  gap: 40px;
}
.c-tournaments__content {
  flex-direction: row;
  display: flex;
  gap: 10px;
}
.c-tournament_card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  padding: 0.5rem 1.25rem;
  color: #FFFFFF;
  background: linear-gradient(150deg, #6C8DFF 0%, #1044FF 100%);
  border-radius: 0.5rem;
  width: 100%;
  height: 490px;
}
.c-tournament_card_header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.5rem;
  z-index: 1;
}
.c-tournament_card_title {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}
.c-tournament_card_active_text {
  color: #ffe500;
  font-weight: 600;
}
.c-tournament_card_active_text_big {
  color: #ffe500;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
}
.c-tournament_card_body_prizepool {
  margin-bottom: 0;
  line-height: 3rem;
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  color: #ffe500;
  display: flex;
  justify-content: center;
}
.c-tournament_card_body_info {
  display: flex;
  flex-direction: column;
}
.c-tournament_card_body_info span:nth-child(4) {
  margin-bottom: 30px;
}
.c-tournament_card_body_description {
  font-size: 1.5rem;
  min-height: 3rem;
  line-height: 2rem;
  font-weight: 400;
  margin: 1rem 0;
}
.c-tournament_card_body_description_bold {
  font-size: 16px;
  line-height: 1.5rem;
  font-weight: 700;
  min-height: 3rem;
  margin-bottom: 0.5rem;
}
.c-tournament_card_body_score {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 4px;
}
.c-tournament_card_body_trophies {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.c-tournament_card_body_trophies svg:nth-child(1) {
  fill: #ffe500;
}
.c-tournament_card_body_trophies svg:nth-child(2) {
  fill: #5d7eff;
}
.c-tournament_card_body_trophies svg:nth-child(3) {
  fill: #5d7eff;
}
.c-tournament_card_body_icon-plus {
  position: absolute;
  bottom: 0;
  right: 3rem;
  width: 8.5rem;
  height: 8.5rem;
  transform: translateY(-50%);
  border-width: initial;
  pointer-events: none;
}
.c-tournament_card_body_icon-plus span {
  font-size: 7rem;
  translate: 100%;
  position: absolute;
  font-weight: 900;
  top: -1px;
  left: -4px;
}
.c-tournament_card_body_icon-plus::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: calc(100% - 1.5rem);
  height: calc(100% - 1.5rem);
  opacity: 0.3;
  background: none;
  border-width: 12px;
  border-style: solid;
  border-color: rgb(255, 255, 255);
  border-image: initial;
  border-radius: 50%;
  width: 104px;
  height: 104px;
}
.c-tournament_card_body_timer {
  display: flex;
  flex-direction: column;
  width: 12rem;
  margin: 0;
  line-height: 1;
  text-align: center;
  margin-top: 50px;
}
.c-tournament_card_body_timer_title {
  font-size: 16px;
  line-height: 1.5rem;
  font-weight: 400;
  margin-bottom: 10px;
}
.c-tournament_card_body_timer_number {
  font-size: 20px;
  font-weight: bold;
}
.c-tournament_card_body_timer_numbers {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.c-tournament_card_body_timer_content {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}
table a:not([class]) {
  color: #ffeeaa;
  text-decoration: none;
}
table a:not([class]):hover, table a:not([class]):focus {
  text-decoration: underline;
}
table th {
  background: white;
}
table th, table td {
  padding: 7px 10px;
  border: 2px solid #1245FF;
  min-width: 120px;
  text-align: center;
}

.c-table__wrapper {
  margin-bottom: 15px;
  background-color: white;
  padding: 0 10px;
  width: 100%;
  overflow-x: scroll;
}
.c-table__wrapper::-webkit-scrollbar {
  display: none;
}

.c-main {
  max-width: 100%;
}
.c-main h1, .c-main h2, .c-main h3, .c-main h4, .c-main h5, .c-main h6 {
  margin-bottom: 10px;
  line-height: 1.2;
}

.c-footer {
  color: #FFFFFF;
  background-color: #1b1c29;
}
.c-footer__container {
  padding: 0 16px;
}
.c-footer__content {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}
.c-footer__info_icons {
  display: flex;
}
.c-footer__info_logo {
  display: flex;
  flex-direction: row;
  padding: 26px 0;
  order: 2;
  border-top: 1px solid #52575C;
}
.c-footer__info_logo span {
  font-size: 14px;
  line-height: 1.25rem;
  font-weight: 400;
  margin-bottom: 2.5rem;
  color: #A6ABB0;
  display: none;
}
.c-footer__games {
  flex-direction: column;
  align-items: flex-start;
  display: none;
}
.c-footer__games span {
  font-size: 20px;
  line-height: 1.5rem;
  font-weight: 700;
  margin-bottom: 2em;
  color: #FFFFFF;
}
.c-footer__games div {
  flex-direction: column;
  display: flex;
}
.c-footer__games a {
  margin: 0 0 1rem;
  font-size: 14px;
  line-height: 1.25rem;
  font-weight: 400;
  color: #A6ABB0;
}
.c-footer__games a:hover {
  color: #FFFFFF;
  text-decoration: underline;
}
.c-footer__games_mobile {
  padding: 12px;
  background: #282A39;
  border-radius: 8px;
  width: 100%;
  font-weight: 700;
  display: flex;
  margin-bottom: 10px;
}
.c-footer__games_mobile_content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.c-footer__games_mobile_content a {
  margin: 0 0 1rem;
  font-size: 14px;
  line-height: 1.25rem;
  font-weight: 400;
  color: #A6ABB0;
  flex-basis: 50%;
  padding: 0 2rem 0 0;
  margin-bottom: 2rem;
  line-height: 1.5rem;
}
.c-footer__info {
  display: none;
  flex-direction: column;
  align-items: flex-start;
}
.c-footer__info span {
  font-size: 20px;
  line-height: 1.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: #FFFFFF;
}
.c-footer__info div {
  flex-direction: column;
  display: flex;
}
.c-footer__info a {
  margin: 0 0 1rem;
  font-size: 14px;
  line-height: 1.25rem;
  font-weight: 400;
  color: #A6ABB0;
}
.c-footer__info a:hover {
  color: #FFFFFF;
  text-decoration: underline;
}
.c-footer__info_mobile {
  padding: 12px;
  background: #282A39;
  border-radius: 8px;
  width: 100%;
  font-weight: 700;
  display: flex;
  margin-bottom: 10px;
}
.c-footer__info_mobile_content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.c-footer__info_mobile_content a {
  margin: 0 0 1rem;
  font-size: 14px;
  line-height: 1.25rem;
  font-weight: 400;
  color: #A6ABB0;
  flex-basis: 50%;
  padding: 0 2rem 0 0;
  margin-bottom: 2rem;
  line-height: 1.5rem;
}
.c-footer__links {
  margin: 0 0 1rem;
  display: none;
  flex-direction: column;
  align-items: flex-start;
}
.c-footer__links span {
  font-size: 20px;
  line-height: 1.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: #FFFFFF;
}
.c-footer__links div {
  flex-direction: column;
  display: flex;
}
.c-footer__links div a {
  margin: 0 0 1rem;
  font-size: 14px;
  line-height: 1.25rem;
  font-weight: 400;
  color: #A6ABB0;
}
.c-footer__links div a:hover {
  color: #FFFFFF;
  text-decoration: underline;
}
.c-footer__links_mobile {
  padding: 12px;
  background: #282A39;
  border-radius: 8px;
  width: 100%;
  font-weight: 700;
  display: flex;
}
.c-footer__links_mobile_content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.c-footer__links_mobile_content a {
  margin: 0 0 1rem;
  font-size: 14px;
  line-height: 1.25rem;
  font-weight: 400;
  color: #A6ABB0;
  flex-basis: 50%;
  padding: 0 2rem 0 0;
  margin-bottom: 2rem;
}
.c-footer__help {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.c-footer__support {
  display: flex;
  flex-direction: row;
  gap: 14px;
  align-items: center;
  color: #FFFFFF;
  background: #32333e;
  border-radius: 20px;
  padding: 4px 10px;
  width: 120px;
  height: 48px;
  flex: 1 1 0;
}
.c-footer__support:hover {
  background: #272730;
}
.c-footer__flag {
  display: flex;
  flex-direction: row;
  gap: 14px;
  align-items: center;
  color: #FFFFFF;
  background: #32333e;
  border-radius: 20px;
  padding: 4px 14px;
  width: 100px;
  height: 48px;
}
.c-footer__flag:hover {
  background: #272730;
}
.c-footer__flag span {
  font-size: 14px;
}
.c-footer__flag_icon {
  position: relative;
  overflow: hidden;
  margin-right: 0.5rem;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
}
.c-footer__flag_icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  transform: translate(-50%, -50%);
}
.c-footer__partner {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  color: #FFFFFF;
  background: #32333e;
  border-radius: 20px;
  padding: 4px 14px;
  width: 100%;
  height: 48px;
}
.c-footer__partner:hover {
  background: #272730;
}
.c-footer__text {
  margin: 30px 0;
}
.c-footer__text p {
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  margin-bottom: 3rem;
  color: #7f868e;
}
.c-footer__text span {
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #7f868e;
  width: 100%;
  justify-content: center;
  display: flex;
}
.c-footer__copyright {
  width: 100%;
  height: 84px;
  padding: 16px 0;
  color: #FFFFFF;
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.c-footer__copyright p {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  text-align: center;
}
.c-footer__mobile {
  margin-bottom: 20px;
  order: 3;
}

@media screen and (min-width: 472px) {
  .c-banner__text_mobile {
    width: unset;
  }
}
@media screen and (min-width: 576px) {
  .c-header .o-btn_green_animation {
    display: flex;
  }
  .c-header .o-btn_search {
    display: none;
  }
  .c-jackpot .c-slot {
    width: 33%;
  }
  .c-slot__overflow-demo {
    display: inline-block;
  }
  .o-container {
    padding: 0 32px;
  }
}
@media screen and (min-width: 768px) {
  .c-table-of-content {
    flex-direction: row;
  }
}
@media screen and (min-width: 900px) {
  .c-table-of-content__item {
    width: 50%;
  }
}
@media screen and (min-width: 950px) {
  .c-footer__content {
    flex-direction: row;
    border-bottom: 1px solid #52575C;
    justify-content: space-between;
  }
  .c-footer__container {
    padding: 0 32px;
  }
  .c-footer__help {
    flex-direction: column;
    gap: 10px;
    align-items: flex-end;
    margin-top: unset;
  }
  .c-footer__mobile {
    display: none;
  }
  .c-footer__games {
    display: flex;
    width: 200px;
    margin-left: 40px;
  }
  .c-footer__games a {
    margin: 0 0 2rem;
  }
  .c-footer__links {
    display: flex;
  }
  .c-footer__links span {
    line-height: 2rem;
  }
  .c-footer__links a {
    margin: 0 0 2rem;
  }
  .c-footer__info {
    display: flex;
    margin: 0px 40px;
    width: 200px;
  }
  .c-footer__info a {
    margin: 0 0 2rem;
  }
  .c-footer__info_logo {
    order: unset;
    flex-direction: column;
    padding: unset;
    border-top: unset;
  }
  .c-footer__info_logo span {
    display: flex;
    margin-bottom: unset;
    padding: 10px 0 40px 0;
  }
  .c-footer__info__icons {
    display: unset;
  }
  .c-footer__support {
    width: 140px;
    flex: unset;
  }
  .c-footer__partner {
    width: 250px;
    gap: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .c-jackpot_bird {
    position: absolute;
    left: 0;
    top: -10px;
    display: block;
    height: 403px;
    z-index: 2;
  }
  .c-jackpot_content {
    flex-direction: row;
    padding: 4rem 0px 5.25rem 8%;
  }
  .c-jackpot_block {
    margin-right: 1rem;
    min-height: 250px;
    min-width: 490px;
  }
  .c-jackpot_block_title {
    font-size: 24px;
  }
  .c-jackpot_block_money {
    margin-top: 16px;
    font-size: 52px;
  }
  .c-jackpot .c-slots {
    justify-content: space-evenly;
  }
  .c-tournament_card_body_prizepool {
    font-style: 56px;
  }
  .c-tournament_card_active_text_big {
    font-size: 36px;
  }
}
@media screen and (min-width: 1200px) {
  .c-header {
    padding: 0 32px;
    height: 64px;
  }
  .c-header__nav {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    margin: 0 -0.5rem;
    scrollbar-width: none;
    flex-wrap: nowrap;
    overflow: auto;
  }
  .c-header__nav_item {
    font-size: 14px;
    line-height: 1.5rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: #000000;
    padding: 0 14px;
  }
  .c-header__nav_item svg {
    display: block;
    max-height: 1.5rem;
    margin-right: 0.25rem;
  }
  .c-header__nav_item:hover {
    text-decoration: underline;
  }
  .c-header .o-btn_blue {
    display: flex;
  }
  .c-header__burger__menu {
    display: none;
  }
  .c-header__logo {
    margin-left: unset;
    display: flex;
    align-items: center;
  }
  .c-banner {
    position: relative;
  }
  .c-banner .o-btn_primary {
    width: 140px;
    height: 48px;
    margin: 0.5rem 0 0;
  }
  .c-banner .o-btn_primary svg {
    display: none;
  }
  .c-banner__text_block {
    max-width: 322px;
    position: absolute;
    top: 50%;
    left: 5.5rem;
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    transform: translate(0, -50%);
    background: unset;
    align-items: flex-start;
  }
  .c-banner__text_mobile {
    display: none;
  }
  .c-banner__text_desktop {
    display: flex;
    flex-direction: column;
  }
  .c-banner__text_desktop strong {
    color: #5ebbff;
    font-size: 36px;
    font-weight: bold;
  }
  .c-banner__text_desktop span {
    font-size: 16px;
  }
}