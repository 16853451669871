*,
*:before,
*:after {
    box-sizing: border-box;
}

*:focus {
    outline: none !important;
}

html {
    font-size: 62.5%;
    scroll-padding-top: 70px;
}

body {
    color: #000;
    font-family: "Roboto", sans-serif;
    background: #F5F5F5;
    font-size: 1.6rem;
}

.svg-none {
    display: none;
}

.o-text {
    &-bold {
        font-weight: bold;
    }

    &-center {
        text-align: center;
    }

    &-up {
        text-transform: uppercase;
    }
}

img {
    max-width: 100%;
    height: auto;
}

button,
a {
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    cursor: pointer;
    transition: .2s;
    text-decoration: none;
    color: inherit;

    & picture img:hover {
        transform: scale(1.02);
    }
}

h1,
.h1 {
    font-size: $h1-font-size;
}

h2,
.h2 {
    font-size: $h2-font-size;
}

h3,
.h3 {
    font-size: $h3-font-size;
}

h4,
.h4 {
    font-size: $h4-font-size;
}

h5,
.h5 {
    font-size: $h5-font-size;
}

h6,
.h6 {
    font-size: $h6-font-size;
}

.lazyload,
.lazyloading {
    opacity: 0;
}

.lazyloaded {
    opacity: 1;
    transition: .2s;
}

.o-btn {
    background: transparent;
    overflow: hidden;
    width: 100%;
    min-height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 1rem;
    line-height: 1rem;
    font-weight: 500;
    text-align: center;
    border-radius: 100px;
    height: 48px;
    max-width: 190px;
    font-size: 14px;

    &_green {
        background: linear-gradient(-45deg, #2EC417 0%, #2EC417 25%, #77d968 50%, #2EC417 75%, #2EC417 100%) left top;

        &:hover {
            background: linear-gradient(16deg, #19bd00 0%, #1bc601 25%, #53e73c 50%, #77f065 75%, #7fff6b 100%) right bottom;
        }

        &_animation {
            position: relative;
            background: linear-gradient(16deg, #2EC417 0%, #2EC417 25%, #77d968 50%, #2EC417 75%, #2EC417 100%) right bottom;
            overflow: hidden;
            height: 3.5rem;
            padding: 0px 30px;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 400%;
                height: 100%;
                background: url(../img/button-animation.svg) no-repeat left 0 top 0;
                background-size: contain;
                animation: fly 2s linear 1s infinite;
            }

            &:hover {
                background: linear-gradient(16deg, #19bd00 0%, #1bc601 25%, #53e73c 50%, #77f065 75%, #7fff6b 100%) right bottom;
            }

            width: 116px;
            height: 40px;
        }
    }

    &_white {
        background: linear-gradient(-45deg, #F3F3F3 0%, #F3F3F3 25%, #e7e7e7 50%, #F3F3F3 75%, #F3F3F3 100%) right bottom;
        color: black;

        &:hover {
            background: linear-gradient(45deg, #dfdfdf 0%, #dfdfdf 25%, #dbdbdb 50%, #dfdfdf 75%, #dfdfdf 100%) right bottom;
        }
    }

    &_blue {
        position: relative;
        width: 70px;
        height: 40px;
        background: linear-gradient(-45deg, #1245FF 0%, #1245FF 25%, #6586ff 50%, #1245FF 75%, #1245FF 100%) right bottom;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            width: 200%;
            height: 100%;
            background-size: 100% 100%;
            transition: 0.2s;
            z-index: -1;
            left: -100%;
        }

        &:hover {
            background: linear-gradient(-45deg, #3762ff 0%, #315dff 25%, #6586ff 50%, #0436ea 75%, #0037ff 100%) right bottom;
        }

        &_animation {
            position: relative;
            background: linear-gradient(-45deg, #1245FF 0%, #1245FF 25%, #6586ff 50%, #1245FF 75%, #1245FF 100%) right bottom;
            overflow: hidden;
            height: 3.5rem;
            padding: 0px 30px;
            color: #fff;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 400%;
                height: 100%;
                background: url(../img/button-animation.svg) no-repeat left 0 top 0;
                background-size: contain;
                animation: fly 2s linear 1s infinite;
            }

            &:hover {
                background: linear-gradient(-45deg, #3762ff 0%, #315dff 25%, #6586ff 50%, #0436ea 75%, #0037ff 100%) right bottom;
            }

            width: 116px;
            height: 40px;
        }
    }

    &_search {
        position: relative;
        width: 40px;
        height: 40px;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            width: 200%;
            height: 100%;
            background-size: 100% 100%;
            transition: 0.2s;
            z-index: -1;
            right: -100%;
            background: linear-gradient(-45deg, #F3F3F3 0%, #F3F3F3 25%, #e7e7e7 50%, #F3F3F3 75%, #F3F3F3 100%) right bottom;
        }
    }

    &_primary {
        position: relative;
        background: linear-gradient(-45deg, #1245FF 0%, #1245FF 25%, #6586ff 50%, #1245FF 75%, #1245FF 100%) right bottom;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            width: 200%;
            height: 100%;
            background-size: 100% 100%;
            transition: 0.2s;
            z-index: -1;
            left: -100%;
        }

        &:hover {
            background: linear-gradient(-45deg, #3762ff 0%, #315dff 25%, #6586ff 50%, #0436ea 75%, #0037ff 100%) right bottom;
        }
    }
}