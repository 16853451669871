// @media screen and (min-width: 390px) {}

@media screen and (min-width: 472px) {
    .c-banner {
        &__text {
            &_mobile {
                width: unset;
            }
        }
    }
}

@media screen and (min-width: 576px) {
    .c-header {
        & .o-btn_green_animation {
            display: flex;
        }

        & .o-btn_search {
            display: none;
        }
    }

    .c-jackpot {
        & .c-slot {
            width: 33%;
        }
    }

    .c-slot {
        &__overflow-demo {
            display: inline-block;
        }
    }

    .o-container {
        padding: 0 32px;
    }
}

@media screen and (min-width: 768px) {
    .c-table-of-content {
        flex-direction: row;
    }


}

@media screen and (min-width: 900px) {
    .c-table-of-content {
        &__item {
            width: 50%;
        }
    }
}

@media screen and (min-width: 950px) {
    .c-footer {
        &__content {
            flex-direction: row;
            border-bottom: 1px solid #52575C;
            justify-content: space-between;
        }

        &__container {
            padding: 0 32px;
        }

        &__help {
            flex-direction: column;
            gap: 10px;
            align-items: flex-end;
            margin-top: unset;
        }

        &__mobile {
            display: none;
        }

        &__games {
            display: flex;
            width: 200px;
            margin-left: 40px;

            & a {
                margin: 0 0 2rem;
            }
        }

        &__links {
            display: flex;

            & span {
                line-height: 2rem;
            }

            & a {
                margin: 0 0 2rem;
            }
        }

        &__info {
            display: flex;
            margin: 0px 40px;
            width: 200px;

            & a {
                margin: 0 0 2rem;
            }

            &_logo {
                order: unset;
                flex-direction: column;
                padding: unset;
                border-top: unset;

                & span {
                    display: flex;
                    margin-bottom: unset;
                    padding: 10px 0 40px 0;
                }
            }

            &__icons {
                display: unset;
            }
        }

        &__support {
            width: 140px;
            flex: unset;
        }

        &__partner {
            width: 250px;
            gap: 12px;
        }
    }
}


@media screen and (min-width: 1024px) {
    .c-jackpot {
        &_bird {
            position: absolute;
            left: 0;
            top: -10px;
            display: block;
            height: 403px;
            z-index: 2;
        }

        &_content {
            flex-direction: row;
            padding: 4rem 0px 5.25rem 8%;
        }

        &_block {
            margin-right: 1rem;
            min-height: 250px;
            min-width: 490px;

            &_title {
                font-size: 24px;
            }

            &_money {
                margin-top: 16px;
                font-size: 52px;
            }
        }

        & .c-slots {
            justify-content: space-evenly;
        }
    }

    .c-tournament {
        &_card_body_prizepool {
            font-style: 56px;
        }

        &_card_active_text_big {
            font-size: 36px;
        }
    }
}

@media screen and (min-width: 1200px) {
    .c-header {
        padding: 0 32px;
        height: 64px;

        &__nav {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-items: center;
            margin: 0 -0.5rem;
            scrollbar-width: none;
            flex-wrap: nowrap;
            overflow: auto;

            &_item {
                font-size: 14px;
                line-height: 1.5rem;
                font-weight: 500;
                display: flex;
                align-items: center;
                color: #000000;
                padding: 0 14px;

                & svg {
                    display: block;
                    max-height: 1.5rem;
                    margin-right: 0.25rem;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        & .o-btn_blue {
            display: flex;
        }

        &__burger__menu {
            display: none;
        }

        &__logo {
            margin-left: unset;
            display: flex;
            align-items: center;
        }
    }

    .c-banner {
        position: relative;

        & .o-btn_primary {
            width: 140px;
            height: 48px;
            margin: 0.5rem 0 0;

            & svg {
                display: none;
            }
        }

        &__text {
            &_block {
                max-width: 322px;
                position: absolute;
                top: 50%;
                left: 5.5rem;
                display: flex;
                flex-direction: column;
                color: #FFFFFF;
                transform: translate(0, -50%);
                background: unset;
                align-items: flex-start;
            }

            &_mobile {
                display: none;
            }

            &_desktop {
                display: flex;
                flex-direction: column;

                & strong {
                    color: #5ebbff;
                    font-size: 36px;
                    font-weight: bold;
                }

                & span {
                    font-size: 16px;
                }
            }
        }
    }
}